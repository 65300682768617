import React from 'react'
import NavBar from '../NavBar/NavBar'
import VendorsTable from './VendorsTable'

export default function Vendors() {
    const {idToken} = JSON.parse(localStorage.getItem('okta-token-storage'))
    
    return (
        <>
            <section className='vh-100 '>
              <div className="row p-0 m-0 ">
                    <div className="col-md-1">
                        <NavBar />
                    </div>
                    <div className="col-md-11  ">
                        <div className="container-fluid ps-3 mt-3 ">
                            <div className="row">
                                <div className="col-md-4">
                                    <h1 className="title">Vendor Portal</h1>
                                </div>
                                <p className='p-text '>Welcome <span className=''>{idToken.claims.name}</span> to <span className=''>Lendistry</span> Vendor Portal. In this portal you will be able to complete onboarding questionnaires as well as upload requested documents. </p>
                                <p className='p-text fw-bold '>Open Assignments</p>
                                <VendorsTable pirm_addon__Status__c={false}/>
                                <p className='p-text fw-bold pt-5'>Completed Assignments</p>
                                <VendorsTable pirm_addon__Status__c={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}