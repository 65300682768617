import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { VendoContext } from '../../Context/vendorContext';
import { useLocation } from 'react-router-dom';
import Questions from './Questions';
import css from './style.module.css';

export default function Questionnaires() {
    const location = useLocation();
    const { id, pirm_addon__Status__c, Audits_r } = location.state;


    const { getVendorQuestionnaire } = useContext(VendoContext);

    const [questionnaire, setQuestionnaire] = useState([]);
    const [allVendorAnswer, setAllVendorAnswer] = useState([]);
    const [status, setStatus] = useState({});
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const fetchQuestionnaire = async () => {
            if (id) {
                try {
                    const data = await getVendorQuestionnaire(id);
                    setAllVendorAnswer(data.filter((e) => (
                        e.pirm_addon__QuestionType__c !== "Blocker"
                    )).map((e) => (
                        e.pirm_addon__QuestionType__c === "Document Upload" ?
                            {
                                Id: e.Id,
                                pirm_addon__Answer__c: e.ContentDocumentLinks?.totalSize || null
                            } :
                            {
                                Id: e.Id,
                                pirm_addon__Answer__c: e.pirm_addon__Answer__c
                            }
                    )));

                    console.log(data.filter((e) => (
                        e.pirm_addon__QuestionType__c !== "Blocker"
                    )).map((e) => (
                        e.pirm_addon__QuestionType__c === "Document Upload" ?
                            {
                                Id: e.Id,
                                pirm_addon__Answer__c: e.ContentDocumentLinks?.totalSize || null
                            } :
                            {
                                Id: e.Id,
                                pirm_addon__Answer__c: e.pirm_addon__Answer__c
                            }
                    )));

                    const groupedData = data.reduce((acc, item) => {
                        const key = item.pirm_addon__Question__r.pirm_addon__Template__r.Name;
                        if (!acc[key]) acc[key] = [];
                        acc[key].push(item);
                        return acc;
                    }, {});

                    setQuestionnaire(Object.values(groupedData));
                    const initialStatus = Object.keys(groupedData).reduce((acc, _, index) => {
                        acc[index] = index === 0;
                        return acc;
                    }, {});
                    setStatus(initialStatus);
                } catch (error) {
                    console.error('Error fetching vendor questionnaire:', error);
                }
            }
        };

        fetchQuestionnaire();
    }, [id, getVendorQuestionnaire]);

    const handleClick = (index) => {
        setStatus(() => {
            const updatedStatus = {};
            Object.keys(status).forEach((key) => {
                updatedStatus[key] = parseInt(key) === index;
            });
            setPosition(index);
            return updatedStatus;
        });
    };

    return (
        <section>
            <div className="row p-0 m-0">
                <div className="col-md-1">
                    <NavBar />
                </div>
                <div className="col-md-11">
                    <div className="container-fluid ps-3 mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h1 className="title">Vendor Portal</h1>
                            </div>
                            <div>
                                <h1 className="title ms-4 mt-3">Vendor Questionnaire</h1>
                                <p className="p-text fs-6 ps-4">
                                    Please answer all questions to complete the vendor onboarding questionnaire.
                                    Our vendor management team will reach out should there be any additional questions.
                                    Please reach out to “client@client.com” if there are any questions regarding the questionnaire.
                                </p>
                                <div className="border-top border-3 position-relative mt-5 mb-4 w-50 start-50 translate-middle-x d-flex justify-content-between">
                                    {questionnaire.map((e, index) => (
                                        <div key={index}>
                                            <div className="position-absolute bottom-50 translate-middle-x w-100">
                                                <p className="text-center ps-2 fs-6">
                                                    {e[0].pirm_addon__Question__r.pirm_addon__Template__r.Name}
                                                </p>
                                            </div>
                                            <div
                                                onClick={() => handleClick(index)}
                                                className="d-flex justify-content-center align-items-center position-absolute top-50 translate-middle-y"
                                                style={{
                                                    width: '22px',
                                                    height: '22px',
                                                    borderRadius: '50%',
                                                    background: '#fff',
                                                    cursor: 'pointer'
                                                }}>
                                                <div
                                                    className="d-flex justify-content-center align-items-center position-absolute top-50 translate-middle-y"
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        borderRadius: '50%',
                                                        background: status[index] ? '#0176D3' : '#fff'
                                                    }}>
                                                    <div
                                                        style={{
                                                            width: '8px',
                                                            height: '8px',
                                                            borderRadius: '50%',
                                                            background: status[index] ? '#fff' : '#C9C9C9'
                                                        }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Questions
                                    questionnaire={questionnaire}
                                    position={position}
                                    id={id}
                                    Audits_r={Audits_r}
                                    pirm_addon__Status__c={pirm_addon__Status__c}
                                    allVendorAnswer={allVendorAnswer} setAllVendorAnswer={setAllVendorAnswer}
                                />
                                <div className="d-flex justify-content-end align-items-center text-center mb-4">
                                    {questionnaire.length > 1 && (
                                        <>
                                            {position === 0 ? (
                                                <button
                                                    className={`btn ${css.nextSectionbtn} text-white mt-3`}
                                                    onClick={() => handleClick(position + 1)}>
                                                    Next Section
                                                </button>
                                            ) : position === questionnaire.length - 1 ? (
                                                <button
                                                    className={`btn ${css.nextSectionbtn} text-white mt-3`}
                                                    onClick={() => handleClick(position - 1)}>
                                                    Prev Section
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        className={`btn ${css.nextSectionbtn} text-white mt-3 me-2`}
                                                        onClick={() => handleClick(position - 1)}>
                                                        Prev Section
                                                    </button>
                                                    <button
                                                        className={`btn ${css.nextSectionbtn} text-white mt-3`}
                                                        onClick={() => handleClick(position + 1)}>
                                                        Next Section
                                                    </button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
