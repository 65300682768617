import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { SalesforceContext } from "./SF.CredantialsContext";

export const VendoContext = createContext(0);

export default function VendorProvider({ children }) {
  const { salesforce } = useContext(SalesforceContext);
  const [Vendors, setVendors] = useState([]);
  


  useEffect(() => {
    if (Object.keys(salesforce).length) {
      getVendors(salesforce);
    }
  }, [salesforce]);


  const getVendors = async (salesforce) => {
    try {
      const { data } = await axios.post(
        "https://api-skhwrpqgyq-uc.a.run.app/vendorlist",
        salesforce
      );
      // console.log(data);
      

      setVendors(data.data);
    } catch (error) {
      console.error("Error fetching policies:", error.message);
    }
  };
  const getVendorQuestionnaire = async (id) => {
    try {
      const { data } = await axios.post(
        `https://api-skhwrpqgyq-uc.a.run.app/questionnaire/${id}`,
        salesforce
      );
      // console.log(data.data);
      
      return data.data;
    } catch (error) {
      console.error("Error fetching policies:", error.message);
    }
  };

  return (
    <VendoContext.Provider value={{ Vendors, getVendorQuestionnaire }}>
      {children}
    </VendoContext.Provider>
  );
}