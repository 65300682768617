import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { Security, LoginCallback } from "@okta/okta-react";
import LayOuts from "./components/LayOuts/LayOuts";
import Home from "./components/Home/Home";
import PolicesDetails from "./components/PolicesDetails/PolicesDetails";
import { useEffect } from "react";
import Protected from "./components/Protected/Protected";
import PoliciesProvider from "./Context/ploicesContext";

import { toRelativeUrl } from "@okta/okta-auth-js";
import Login from "./components/Login/Login";
import AppProvider from "./Context/AppContext";
import {
  OktaConfigProvider,
  useOktaConfig,
} from "./Context/CredentialsContext";
import Domain from "./components/Login/Domain";
import Vendors from "./components/Vendors/Vendors";
import Questionnaires from "./components/Vendors/Questionnaires";
import VendorProvider from "./Context/vendorContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SalsefoeceProvider from "./Context/SF.CredantialsContext";

const CALLBACK_PATH = "/login/callback";

function App() {
  const navigate = useNavigate();
  const { oktaAuths, setOktaConfig } = useOktaConfig();

  useEffect(() => {
    const credentials = JSON.parse(localStorage.getItem("credentials"));
    if (credentials) {
      setOktaConfig(credentials);
    }
    console.log("oktaAuths: ", oktaAuths);
  }, [setOktaConfig]); // Ensure useEffect only depends on setOktaConfig

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  if (!oktaAuths) {
    return <Domain />;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Compliance Portal</title>
      </Helmet>
      <Security oktaAuth={oktaAuths} restoreOriginalUri={restoreOriginalUri}>
        <SalsefoeceProvider>
          <PoliciesProvider>
          <VendorProvider>
            <AppProvider>
                <Routes>
                  <Route element={<LayOuts />}>
                    <Route index="/" element={<Login />} />
                    <Route
                      path="/home"
                      element={
                        <Protected>
                          <Home />
                        </Protected>
                      }
                    />
                    <Route
                      path="/vendors"
                      element={
                        <Protected>
                          <Vendors />
                        </Protected>
                      }
                    />
                    <Route path={CALLBACK_PATH} element={<LoginCallback />} />
                    <Route
                      path="/details/:name/:id"
                      element={
                        <Protected>
                          <PolicesDetails />
                        </Protected>
                      }
                    />
                    <Route
                      path="/questionnaires"
                      element={
                        <Protected>
                          <Questionnaires />
                        </Protected>
                      }
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Route>
                </Routes>
                </AppProvider>
              </VendorProvider>
          </PoliciesProvider>
        </SalsefoeceProvider>
      </Security>
    </HelmetProvider>
  );
}

export default function Root() {
  return (
    <OktaConfigProvider>
      <App />
    </OktaConfigProvider>
  );
}
