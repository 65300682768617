import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { VendoContext } from '../../Context/vendorContext.js';
import SkeletonVendorPage from '../LoadingPages/SkeletonVendorPage.jsx';

export default function VendorsTable({ pirm_addon__Status__c }) {
  const { Vendors } = useContext(VendoContext);
  const [vendor, setVendor] = useState([]);

  useEffect(() => {
    if (Array.isArray(Vendors)) {
      if (pirm_addon__Status__c) {
        setVendor(Vendors.filter((vendor) => (vendor.pirm_addon__Status__c !== null)));
      } else {
        setVendor(Vendors.filter((vendor) => vendor.pirm_addon__Status__c === null));
      }
    }
  }, [Vendors, pirm_addon__Status__c]);

  if (!vendor.length) {
    return <SkeletonVendorPage/>;
  }

  return (
    <table className="table-bordered documents rounded-table ms-1 p-0" style={{ marginTop: "30px" }}>
      <thead>
        <tr>
          <th scope="col" className="py-1 px-2">#</th>
          <th scope="col" className="py-1 px-2">Name</th>
          <th scope="col" className="py-1 px-2">Type</th>
          <th scope="col" className="py-1 px-2">Date Assigned</th>
          <th scope="col" className="py-1 px-2">Due Date</th>
        </tr>
      </thead>
      <tbody>
        {vendor.map((item, index) => (
          <tr key={index}>
            <th scope="row" className="py-1 px-2 border">{index + 1}</th>
            <td className="py-1 px-2">
              <Link
                to={`/questionnaires`}
                state={{ id: item.Id, pirm_addon__Status__c, Audits_r: item.pirm_addon__Audits__r.records[0].Id }}
                className="text-decoration-none text-dark"
              >
                {item.Name}
              </Link>
            </td>
            <td className="py-1 px-2">Questionnaire</td>
            <td className="py-1 px-2">
              {item.CreatedDate ? (
                `${item.CreatedDate.split("T")[0].split("-").slice(1).join("/")}`
              ) : " "}
            </td>
            <td className="py-1 px-2">
              {item.pirm_addon__Due_Date__c ? (
                `${item.pirm_addon__Due_Date__c.split("T")[0].split("-").slice(1).join("/")}`
              ) : " "}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}